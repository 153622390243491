<template>
    <div id="about" class="page-wrapper page-about" hero-transition-group>
        <!-- Hero -->
        <header class="simple-hero site-max-width" hero-transition-group>
            <div class="hero-container container">
                <h1 class="hero-title title"><span v-html="contentIsLoaded ? content.heroSimple.titre : '&nbsp;'"></span></h1>
            </div>
        </header>

        <!-- Split Content -->
        <section class="split-content site-max-width no-space" hero-transition-group>
            <div class="inner split-content-container" v-if="contentIsLoaded">
                <div class="split-content-content">
                    <h1 class="split-content-title small-title">
                        <span v-html="contentIsLoaded ? content.split2[0].titre : '&nbsp;'"></span>
                    </h1>
                    <p class="split-content-text text">
                        <span v-html="contentIsLoaded ? content.split2[0].texte : '&nbsp;'"></span>
                    </p>
                    <div class="cta"><a href="#" class="site-btn accent" @click.prevent="readMore = true">{{ content.split2[0].bouton.customText }}</a></div>
                </div>
                <div class="split-content-img">
                    <img :src="content.split2[0].image.url" :alt="content.split2[0].image.titre" />
                </div>
            </div>
        </section>

        <!-- Split Content -->
        <section class="split-content revert site-max-width no-space" hero-transition-group>
            <div class="inner split-content-container" v-if="contentIsLoaded">
                <div class="split-content-content">
                    <h1 class="split-content-title small-title">
                        <span v-html="content.split2[1].titre"></span>
                    </h1>
                    <p class="split-content-text text">
                        <span v-html="content.split2[1].texte"></span>
                    </p>
                    <div class="cta"><a :href="content.split2[1].boutonUrl" class="site-btn accent" target="_blank" rel="noopener noreferrer">{{ content.split2[1].bouton.customText }}</a></div>
                </div>
                <div class="split-content-img">
                    <img :src="content.split2[1].image.url" :alt="content.split2[1].image.titre" />
                </div>
            </div>
        </section>

        <!-- Split Content -->
        <section class="split-content site-max-width" hero-transition-group>
            <div class="inner split-content-container" v-if="contentIsLoaded">
                <div class="split-content-content">
                    <h2 class="split-content-title title" data-inview="fadeInUp" data-delay="100">
                        <span v-html="contentIsLoaded ? content.split.titre : '&nbsp;'"></span>
                    </h2>
                    <p class="split-content-text text" data-inview="fadeInUp" data-delay="200">
                        <span v-html="contentIsLoaded ? content.split.texte : '&nbsp;'"></span>
                    </p>
                </div>
                <div class="split-content-imgs" v-if="contentIsLoaded">
                    <a :href="item.lienWeb" target="_blank" rel="noopener noreferrer"
                    v-for="(item, i) in content.split.logos" :key="i"
                        ><img
                            :src="item.url"
                            :alt="item.titre"
                            data-inview="fadeInUp"
                            :data-delay="(4 + i) + '00'"
                    /></a>
                </div>
            </div>
        </section>

        <!-- Popup content -->
        <div class="content-dialog" :class="{ 'is-open': readMore }">
            <button class="close-button" @click.prevent="close()"><svg><use href="#close"></use></svg></button>
            <p class="content-dialog-text">
                <span v-html="contentIsLoaded ? content.split2[0].suite : '&nbsp;'"></span>
            </p>

            <svg>
                <defs>
                    <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" y1="2" x2="12" y2="15"></line></symbol>

                    <symbol id="facebook" viewBox="0 0 24 24" fill="#3b5998" stroke="#3b5998" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></symbol>

                    <symbol id="twitter" viewBox="0 0 24 24" fill="#1da1f2" stroke="#1da1f2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></symbol>

                    <symbol id="email" viewBox="0 0 24 24" fill="#777" stroke="#fafafa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></symbol>

                    <symbol id="linkedin" viewBox="0 0 24 24" fill="#0077B5" stroke="#0077B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></symbol>

                    <symbol id="close" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></symbol>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'About',

    components: {
        // HelloWorld,
    },

    data() {
        return {
            readMore: false
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        close() {
            this.readMore = false
        }
    },
}
</script>
